import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import EN_US from "./languages/en_US.json";
import zh_CN from "./languages/zh_CN.json";
import zh_TW from "./languages/zh_TW.json";

const resources = {
  'en-US': {
    translation: EN_US
  },
  'zh-CN': {
    translation: zh_CN
  },
  'zh-TW': {
    translation: zh_TW
  }
};
i18n.use(initReactI18next).init({
  resources,
  lng: localStorage.getItem('language') || 'zh-CN',
  interpolation: {
    escapeValue: false
  }
});

export default i18n;