import React from "react";
import { useTranslation } from "react-i18next";
import { Breadcrumb } from "antd";
import { useNavigate } from "react-router-dom";

 const BreadcrumbsCustom = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation()
  const toParent = () => {
    navigate(props.routeList?.children[0]?.path);
  }
  return (
    <>
      <Breadcrumb className="mb20 zlink-bread-crumb">
        <Breadcrumb.Item>
          <span onClick={() => toParent()} className="zlink-bread-crumb_span">
            {t(props.routeList?.meta?.i18nKey)}
          </span>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{t(props.path?.meta?.i18nKey)}</Breadcrumb.Item>
      </Breadcrumb>
    </>
  )
}
export default BreadcrumbsCustom
