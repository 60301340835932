
import { auth }  from "zlink-front"; 
// import { initGlobalState } from "qiankun";
export const UPDATELANGUAGE = 'settings/UPDATELANGUAGE'
export const UPDATEMSGREAD = 'settings/UPDATEMSGREAD'
export const UPDATEMSGLIST= 'settings/UPDATEMSGLIST'
export const UPDATEAPPCHANGE = 'settings/UPDATEAPPCHANGE'
export const UPDATEGUIDESTEP = 'settings/UPDATEGUIDESTEP'
export const REMOVEGUIDESTEP = 'settings/REMOVEGUIDESTEP'


const settingsState = {
  language: auth.getLanguage() || 'zh-CN',
  msgRead: new Date().getTime(),
  appChange: new Date().getTime(),
  guideStep: auth.getGuideStep() || null
}
// const globalState = initGlobalState({
//   language: auth.getLanguage() || 'zh-CN'
// })
//eslint-disable-next-line
export default (state = settingsState, action = {}) => {
  let newState = JSON.parse(JSON.stringify({
    ...state
  }))
  switch (action.type) {
    case UPDATELANGUAGE:
      newState.language = action.data
      return {
        ...newState
      }
    case UPDATEMSGREAD:
      newState.msgRead =  new Date().getTime()
      return {
        ...newState
      }
    case UPDATEAPPCHANGE:
      newState.appChange =  new Date().getTime()
      return {
        ...newState
      }
    case UPDATEGUIDESTEP:
      newState.guideStep = action.data
      return {
        ...newState
      }
    case REMOVEGUIDESTEP:
      newState.guideStep = null
      return {
        ...newState
      }
    default:
      return state
  }
}

export const updateLanguage = (data) => {
  auth.setLanguage(data)
  // globalState.setGlobalState({
  //   language: data
  // })
  return dispatch => {
     dispatch({
      type: UPDATELANGUAGE,
      data: data 
    })
  }
}

export const updateMsgRead = () => {
  return dispatch => {
     dispatch({
      type: UPDATEMSGREAD
    })
  }
}

export const updateAppChange = () => {
  return dispatch => {
     dispatch({
      type: UPDATEAPPCHANGE
    })
  }
}

export const updateGuideStep = (data) => {
  auth.setGuideStep(data)
  return dispatch => {
     dispatch({
      type: UPDATEGUIDESTEP,
      data: data 
    })
  }
}

export const removeGuideStep = () => {
  auth.removeGuideStep()
  return dispatch => {
     dispatch({
      type: REMOVEGUIDESTEP
    })
  }
}