import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal, Input, Tree, Button, message } from 'antd';
import "@/assets/scss/device.scss";
import { getTreeReportEmpNode, getEmployeeList } from "@/api/org";
import { UserOutlined, FolderFilled, CloseOutlined } from '@ant-design/icons';
import EmpList from "./EmpList";
import { EMP_STATUS } from "@/enums/org.js";
const { Search } = Input;

/**
 * 选择部门树人员弹窗参数说明
 * @param {Boolean} visible  // 弹窗展示/隐藏
 * @param {Function} onChange // 点击确认回调事件，不可与api一起使用
 * @param {Function} close // 关闭窗口
 * @param {String} id  // 权限组ID
 * @param {Function} api // 点击确认请求接口，不可与onChange一起使用
 * 
 * @param {Boolean} ProhibitedDep // true 禁止选择部门
 * @param {Boolean} PersonnelIDList // 已选择人员id列表  ProhibitedDep为true生效 
 * @param {Boolean} mode // 模式 single：单选 multiple：多选 注： 默认多选
 * @param {String} title  // 窗口名称 默认 选择人员
 * 
 */

const SelectOrgPersonModal = (props) => {
  const { t } = useTranslation()
  const [show, setShow] = useState(false)
  const [treeData, setTreeData] = useState([])
  const [checkedKeys, setCheckedKeys] = useState([])
  const [selectedKeys, setSelectedKeys] = useState([])
  const [checkedNodes, setCheckedNodes] = useState([])
  const [expandedKeys, setExpandedKeys] = useState([])
  const [searchValue, setSearchValue] = useState('')
  const [searchValueEnter, setSearchValueEnter] = useState('')

  // 获取tree数据
  const getTreeReportEmpNodeFn = async (isInit, nodeNumber, empName, deptId, hideLoading) => {
    setTreeData([])
    try {
      const res = await getTreeReportEmpNode(nodeNumber, empName, deptId, hideLoading)
      const emps = await getEmployeeList({
        pageNumber: 1,
        pageSize: 10,
        departmentId: res[0].id ? [res[0].id] : null,
        firstName: "",
        status: [EMP_STATUS["ACTIVE"]],
      });
      if (!!emps?.totalCount) {
        // 初始化一个emp nodeType 1: 部门 2：人员 3：人员列表
        res.forEach(item => {
          const empModel = {
            nodeType: 3,
            parentId: item.id,
            children: null,
            emps: emps,
            id: item?.id + 'nodetype3'
          }
          if (!!item.children && item.children.length) {
            item.children.push(empModel)
          } else {
            item.children = [empModel]
          }
        })
      }
      const list = updatedDefaultTreeData(res, isInit)
      !!list && list.length && setTreeData(list)
      !!res && res.length && setExpandedKeys([res[0].id])
    } catch (e) {
      // console.log(e)
    }
  }

  // 关闭弹窗
  const onClose = (reload) => {
    setSearchValueEnter('')
    setSearchValue('')
    setShow(false)
    props.close(reload)
  }

  const handleIds = (type) => {
    const ids = []
    checkedNodes.forEach(item => {
      item.nodeType === type && ids.push(item.id)
    })
    return ids
  }

  // 确认保存
  const onSave = async () => {
    if (!!props?.api) {
      const params = {
        levelId: props.id,
        personIds: handleIds(2),
        deptIds: handleIds(1)
      }
      try {
        await props.api(params)
        message.success(t("common.opSuccess"));
        onClose(true)
      } catch (e) {
        // console.log(e)
      }
    } else {
      props.onChange({
        levelId: props.id,
        personIds: handleIds(2),
        deptIds: handleIds(1),
        checkedNodes
      })
      onClose(false)
    }
  }

  const updatedDefaultTreeData = (list, isInit) => {
    list.forEach(item => {
      if (!isInit && !!checkedKeys && !!checkedKeys.length) {
        item.disabled = checkedKeys.includes(item.parentId)
      }
      item.isLeaf = item.nodeType === 2 || item.nodeType === 3
      if (props.ProhibitedDep) item.checkable = item.nodeType === 2 || item.nodeType === 3  // 禁止选中
      item.children = !!item?.children ? updatedDefaultTreeData(item.children, isInit) : item?.children
    })
    return list
  }

  // 监听父组件 弹窗显示数据
  useEffect(() => {
    !!props.visible && init() // eslint-disable-next-line react-hooks/exhaustive-deps
    if (props.ProhibitedDep && props.PersonnelIDList) {
      try {
        const nodes = props.PersonnelIDList.map((e) => {
          return {
            id: e.id,
            name: e.firstName || e.name,
          }
        })

        const keys = props.PersonnelIDList.map((e) => {
          return e.id
        })
        setCheckedKeys([...keys])
        setSelectedKeys([...keys])
        setCheckedNodes([...nodes])
      } catch (e) {
        // console.log(e)
      }
    }

    setShow(props.visible) //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.visible]) // eslint-disable-line react-hooks/exhaustive-deps


  // 异步加载树
  const onLoadData = ({ key,
    // children 
  }) =>
    new Promise(resolve => {
      // if (children) {
      //   resolve()
      //   return
      // }
      getTreeReportEmpNode('', '', key).then(res => {
        getEmployeeList({
          pageNumber: 1,
          pageSize: 10,
          departmentId: [key],
          firstName: "",
          status: [EMP_STATUS["ACTIVE"]],
        }).then(emps => {
          let arr = res
          if (!!emps?.totalCount) {
            const empModel = [{
              nodeType: 3,
              parentId: key,
              children: null,
              emps: emps,
              id: key + 'nodetype3'
            }]
            arr = [...arr, ...empModel]
          }
          const list = updatedDefaultTreeData(arr)
          checkedKeys.includes(key) && handleCheckData(list)
          setTreeData(origin => updateTreeData(origin, key, list))
          resolve()
        }).catch(e => {
          // console.log({ e })
        })
        resolve()
      }).catch(e => {
        // console.log({ e })
      })
    })

  const handleCheckData = (list) => {
    const keys = checkedKeys
    list.forEach(item => {
      keys.push(item.id)
    })
    setCheckedKeys(keys)
  }

  // const handleDisable = (key) => {
  //   if(checkedKeys.includes(key)) {
  //     return true
  //   }
  //   const node = checkedNodes.find(item => item.id === key)
  //   if(!!node && !!node.parentId) {
  //     handleDisable(node.parentId)
  //   }
  //   return false

  // }
  // 处理树数据
  const updateTreeData = (list, key, children) =>
    list.map(node => {
      // if(node.nodeType === 3) {
      //   return node
      // }
      const isLeaf = node.nodeType === 2
      if (node.id === key) {
        return {
          ...node,
          isLeaf: isLeaf,
          disabled: checkedKeys.includes(node.parentId),
          children
        };
      }
      if (node.children) {
        return {
          ...node,
          isLeaf: isLeaf,
          // icon: icon,
          disabled: checkedKeys.includes(node.parentId),
          children: updateTreeData(node.children, key, children),
        };
      }
      return node;
    });

  // 处理树禁用数据
  const updateTreeDisabled = (list, id, disabled) =>
    list.map(node => {
      // if(node.nodeType === 3) {
      //   return node
      // }
      if (id === node.parentId) {
        return {
          ...node,
          disabled: disabled,
          children: !!node.children ? updateTreeDisabled(node.children, node?.id, disabled) : []
        }
      }
      return {
        ...node,
        children: !!node.children ? updateTreeDisabled(node.children, id, disabled) : []
      }
    });


  // 处理树已选数据 - 1
  const updateTreeChecked = (node, value, checked) => {
    let origin = dataFilter(value)
    if (!!origin?.length) {
      origin = updateTreeCheckedFn(node, value, checked)
    }
    return origin
  }

  // 处理树已选数据 - 2
  const updateTreeCheckedFn = (node, value, checked) => {
    let origin = [...value]
    !!node?.children?.length && node?.children.forEach(item => {
      checked ?
        origin = dataFilter([...origin, ...[item.id]]) :
        origin?.splice(
          origin.findIndex(fitem => fitem === item.id),
          1
        )
      if (!!item?.children && !!item?.children.length) {
        origin = updateTreeCheckedFn(item, origin, checked)
      }
    })
    return origin
  }

  // 数据去重
  const dataFilter = (arr) => {
    return arr?.filter((item, idx) => {
      return !!item && arr?.indexOf(item) === idx
    })
  }

  // 已选数据过滤 - 1
  const updateNodes = (values, node, checked) => {
    let origin = [...values]
    if (checked) {
      origin.push(node)
      if (!!node?.children?.length) {
        origin = updateNodesFn(origin, node?.children)
      }
    } else {
      origin.splice(
        origin.findIndex(item => item.id === node.id),
        1
      )
    }
    return origin
  }

  // 已选数据过滤 - 2
  const updateNodesFn = (values, node) => {
    let origin = [...values]
    !!node?.length && node.forEach(item => {
      const idx = origin.findIndex(fitem => fitem.id === item.id)
      idx !== -1 && origin.splice(idx, 1)
      if (!!item?.children?.length) {
        origin = updateNodesFn(origin, item.children)
      }
    })
    return origin
  }

  // 勾选 
  const onCheck = (value, e) => {
    if (e.node.nodeType === 3) {
      return
    }
    let arr = [...value.checked]
    let nodes = [...checkedNodes]
    if (arr.length > 0 && nodes.length > 0) {
      checkedNodes.forEach(item => {
        if (arr.includes(item.parentId)) {
          arr.splice(arr.findIndex(i => i === item.id), 1)
          nodes.splice(nodes.findIndex(i => i.id === item.id), 1)
        }
      })
    }
    setSelectedKeys([])
    setCheckedKeys(() => updateTreeChecked(e.node, arr, e.checked))
    setTreeData(origin => updateTreeDisabled(origin, e.node.id, e.checked))
    setCheckedNodes(() => updateNodes(nodes, e.node, e.checked))
  };

  // 选择
  const onSelect = (value, e) => {
    if (e.node.nodeType === 3) {
      return
    }
    if (props.ProhibitedDep && e.node.nodeType === 1) return // 禁止选中部门
    const selected = checkedKeys.findIndex(i => i === value[0]) === -1 // 判断是否选择状态 
    if (props?.mode === 'single') { // 单选
      setSelectedKeys(selected ? value : [])
      setCheckedKeys(selected ? value : [])
      setCheckedNodes(selected ? [e.node] : [])
    } else { // 多选
      setSelectedKeys([])
      let keys = checkedKeys
      // 判断选择状态 取消：移除数据 选中：合并数据
      if (!selected) {
        keys.splice(
          keys.findIndex(i => i === e.node.id),
          1
        )
      } else {
        keys = [...checkedKeys, ...value]
      }
      setCheckedKeys(() => updateTreeChecked(e.node, keys, selected))
      setTreeData(origin => updateTreeDisabled(origin, e.node.id, selected))
      setCheckedNodes(origin => updateNodes(origin, e.node, selected))
    }
  };

  // 删除
  const hanldeDel = (item) => {
    const keys = [...checkedKeys]
    keys.splice(
      keys.findIndex(fItem => fItem === item.id),
      1
    )
    setCheckedKeys(() => updateTreeChecked(item, keys, false))
    setTreeData(origin => updateTreeDisabled(origin, item.id, false))
    setCheckedNodes(origin => updateNodes(origin, item, false))
    setSelectedKeys([])
  }

  const init = () => {
    setSelectedKeys([])
    setCheckedKeys([])
    setCheckedNodes([])
    getTreeReportEmpNodeFn(true, 1, '', '', false)
  }

  const onExpand = (keys) => {
    setExpandedKeys(keys)
  }

  const onRemove = (value) => {
    let keys = checkedKeys
    let nodes = checkedNodes
    value.forEach(item => {
      keys.splice(
        keys.findIndex(i => i === item),
        1
      )
      nodes.splice(
        nodes.findIndex(i => i.id === item),
        1
      )
    })
    setCheckedKeys([...keys])
    setCheckedNodes([...nodes])
  }

  // 自定义title渲染
  const titleRender = ({ name, parentId, nodeType, emps }) => {
    return nodeType === 1 ?
      <div className="text-overflow-one">
        <FolderFilled style={{ color: '#999' }} /> {name}
      </div> : nodeType === 2 ?
        <div className="text-overflow-one">
          <UserOutlined style={{ color: '#999' }} /> {name}
        </div> :
        <EmpList
          mode={props?.mode}
          depId={parentId}
          onSelect={onSelect}
          onCheck={onCheck}
          selectedKeys={selectedKeys}
          checkedKeys={checkedKeys}
          initList={emps}
          onRemove={onRemove}
        />
  }

  return (
    <>
      <Modal
        title={props?.title || t('org.selectPerson')}
        visible={show}
        onOk={onSave}
        onCancel={() => onClose(false)}
        width={900}
        className="zk-select-org-person"
        wrapClassName="zk-fingerprint-modal-wrap"
        maskStyle={{
          zIndex: 1001
        }}
      >
        <div className="zk-select-org-person_cont" >
          <div >
            <div style={{ marginTop: '50px' }}>
              <Search
                placeholder={t('org.searchPersonName')}
                enterButton={t('org.search')}
                value={searchValue}
                onChange={e => setSearchValue(e.target.value)}
                onSearch={value => {
                  // 多选试需要清空 不然部门大于2级有有会有问题（人员只有上级部门，不能查更上层的部门id）
                  if (props?.mode !== 'single') {
                    setSelectedKeys([])
                    setCheckedKeys([])
                    setCheckedNodes([])
                  }
                  setSearchValueEnter(value)
                }}
                style={{
                  position: 'absolute',
                  top: '0',
                  width: '48%',
                  zIndex: '2'
                }}
              />
              {!!searchValueEnter ?
                <>
                  <EmpList
                    mode={props?.mode}
                    onSelect={onSelect}
                    onCheck={onCheck}
                    selectedKeys={selectedKeys}
                    checkedKeys={checkedKeys}
                    onRemove={onRemove}
                    empName={searchValueEnter}
                  />
                </> :
                <>
                  {!!treeData.length &&
                    <Tree
                      loadData={onLoadData}
                      treeData={treeData}
                      fieldNames={{ title: 'name', key: 'id' }}
                      showIcon
                      checkable={props?.mode !== 'single'} // 判断是否check 多选
                      onCheck={onCheck}
                      onSelect={onSelect}
                      checkedKeys={checkedKeys}
                      defaultExpandedAll
                      visible
                      checkStrictly
                      onExpand={onExpand}
                      expandedKeys={expandedKeys}
                      showLine={true}
                      selectedKeys={selectedKeys}
                      titleRender={titleRender}
                    />
                  }
                </>
              }
            </div>
          </div>
          <div>
            <div className="zk-select-org-person_right">
              <div className="zk-select-org-person_title">
                已选
                <Button
                  icon={
                    <i className="iconfont zlink-ic_content_delete" />
                  }
                  type="link"
                  danger
                  onClick={() => init()}
                >
                  清空
                </Button>
              </div>
              
              {
                checkedNodes.length ? <>
                  {checkedNodes.map((item) => {
                    return (
                      <div
                        key={item.id}
                        className="zk-select-org-person_li"
                      >
                        {
                          item.nodeType === 1 ?
                            <FolderFilled style={{ color: '#999' }} /> : <UserOutlined style={{ color: '#999' }} />
                        }
                        {item.name}
                        <CloseOutlined
                          style={{ color: '#ccc' }}
                          onClick={() => hanldeDel(item)}
                        />
                      </div>
                    )
                  })}
                </> : <>
                  <div className="zk-notdata center">暂无数据</div>
                </>
              }
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default SelectOrgPersonModal;
