import React, { useRef, useState } from 'react'
import { useTranslation } from "react-i18next";
import { Button, Modal, message } from "antd";
import { ProTableCustom } from "zlink-front";
import "@/assets/scss/vis.scss";
import { PlusOutlined } from "@ant-design/icons";
import useHorizontalScroll from '../../../hook/useHorizontalScroll'
import VisitorDetailsDrawer from '@/components/VisitorDetailsDrawer'
import VisitRegistrationForm from '@/components/VisitRegistrationForm'
import moment from 'moment';
import { queryVisitorRegistrationList, createVisitorRegistration, signOut } from "@/api/vis";
import { getVisLevelList } from "@/api/acc";

const { confirm } = Modal;


export default function index() {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { t } = useTranslation();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const actionRef = useRef();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [open, setOpen] = useState(false);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useHorizontalScroll('ant-table-body', 300);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [selectedRow, setSelectedRow] = useState({}); // 存储选中的表格行数据
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [registrationOpen, setRegistrationOpen] = useState(false);



  const columns = [
    {
      title: t("访客姓名"),
      key: "visitorName",
      dataIndex: "visitorName",
      hideInSearch: false,
      fixed: 'left',
      width: 150,
      render: (_, r) => r.visitorName || '-'
    },
    {
      title: t("访客手机号"),
      key: "visitorPhone",
      dataIndex: "visitorPhone",
      hideInSearch: false,
      fixed: 'left',
      width: 120,
      render: (_, r) => {
        const phone = r.visitorPhone.replace('+86-', '');
        return phone;
      }
    },
    {
      title: t("访客所属公司"),
      key: "visitorCompanyName",
      dataIndex: "visitorCompanyName",
      hideInSearch: false,
      width: 200,
      render: (_, r) => r.visitorCompanyName || '-'
    },
    {
      title: t("登记时间"),
      key: "checkInDate",
      dataIndex: "checkInDate",
      hideInSearch: false,
      width: 200,
      render: (_, r) => {
        return r.checkInDate ? moment(r.checkInDate).format('YYYY-MM-DD HH:mm') : '-'
      }

    },
    {
      title: t("预约时间"),
      key: "orderTime",
      dataIndex: "orderTime",
      hideInSearch: false,
      width: 300,
      render: (_, r) => {
        const visitDate = r.visitDate ? moment(r.visitDate).format('YYYY-MM-DD HH:mm') : '-'
        const visitEndDate = r.visitEndDate ? moment(r.visitEndDate).format('YYYY-MM-DD HH:mm') : '-'
        return `${visitDate} ~ ${visitEndDate}`
      }
    },
    {
      title: t("签离时间"),
      key: "checkOutDate",
      dataIndex: "checkOutDate",
      hideInSearch: false,
      width: 200,
      render: (_, r) => {
        return r.checkOutDate ? moment(r.checkOutDate).format('YYYY-MM-DD HH:mm') : '-'
      }

    },
    {
      title: t("被访人姓名"),
      key: "visitEmpName",
      dataIndex: "visitEmpName",
      hideInSearch: false,
      width: 200,
      render: (_, r) => r.visitEmpName || '-'

    },
    {
      title: t("来访状态"),
      key: "checkStatus",
      dataIndex: "checkStatus",
      hideInSearch: false,
      width: 200,
      render: (_, r) => {
        const checkStatusMap = {
          1: '未来访',
          2: '已到访',
          3: '已签离',
          4: '已失效',
        }
        return checkStatusMap[r.checkStatus] || '-'
      }
    },
    {
      title: t("来访事由"),
      key: "visitReason",
      dataIndex: "visitReason",
      hideInSearch: false,
      width: 300,
      render: (_, r) => r.visitReason || '-'

    },
    {
      title: t("操作"),
      key: "operation",
      dataIndex: "operation",
      hideInSearch: false,
      fixed: 'right',
      width: 200,
      render: (_, record) => {
        return <>
          <Button type="link" onClick={() => handleOpenDetails(record)}>
            {t('查看详情')}
          </Button>
          <Button danger type="link" disabled={record.checkStatus === 3} onClick={() => {
            confirm({
              title: t("确认访客是否已离开"),
              okText: t("common.confirmOk"),
              okType: "danger",
              cancelText: t("common.confirmCancel"),
              onOk: async () => {
                try {
                  await signOut(record.id)
                  message.success('操作成功');
                  actionRef.current.reload()
                } catch (error) {

                }
              },
            });
          }} >
            {t('签离')}
          </Button>

        </>
      }
    },
  ];
  const item = [
    {
      key: 'visitorName',
      label: '访客姓名',
    },
    {
      key: 'visitorPhone',
      label: '访客手机号',
    },
    {
      key: 'visitorCompanyName',
      label: '访客所属公司',
    },
    {
      key: 'visitReason',
      label: '来访事由',
    },
    {
      key: 'checkInDate',
      label: '登记时间',
    },
    {
      key: 'orderTime',
      label: '预约时间'
    },
    {
      key: 'checkOutDate',
      label: '签离时间',
    },
    {
      key: 'accessGroupName',
      label: '通行权限',
    },
    {
      key: 'visitEmpName',
      label: '被访人',
    },
    {
      key: 'verify',//verifyMode
      label: '验证方式',
    },
    {
      key: 'profilePhotoKey',
      label: '',
      type: 'photo'
    },

  ];
  const handleOpenDetails = (val) => {
    const record = { ...val }
    const verifyModeObj = {
      1: '密码',
      2: '二维码',
      3: '面部照片',
    }
    /** 验证方式 1:密码、2:二维码、3:人脸*/
    record.verify = verifyModeObj[record.verifyMode]

    record.checkInDate = record.checkInDate ? moment(record.checkInDate).format('YYYY-MM-DD HH:mm') : '-'
    record.checkOutDate = record.checkOutDate ? moment(record.checkOutDate).format('YYYY-MM-DD HH:mm') : '-'
    record.visitEndDate = record.visitEndDate ? moment(record.visitEndDate).format('YYYY-MM-DD HH:mm') : '-'
    record.visitDate = record.visitDate ? moment(record.visitDate).format('YYYY-MM-DD HH:mm') : '-'

    record.orderTime = `${record.visitDate} ~ ${record.visitEndDate}`

    setSelectedRow(record); // 更新选中的表格行数据
    setOpen(true); // 打开DetailsDrawer
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const columnsWithEllipsis = columns.map(column => {
    const { render, ...rest } = column;
    const key = column.dataIndex
    return {
      ...rest,
      ellipsis: true,
      render: (_, record) => {
        const text = record[key]
        if (render) return render(_, record)
        if (!render) return <span title={text}>{text}</span>
      }
    };
  });
  const handleOpen = () => {
    setRegistrationOpen(true);
  };

  const handleClose = () => {
    setRegistrationOpen(false);
  };

  const handleFinish = async (values, resetFields) => {
    delete values.visitTime
    const formData = new FormData()
    for (let key in values) {
      formData.append(key, values[key]);
    }
    let res = await createVisitorRegistration(formData)
    // console.log('res: ', res);

    // const visitDate = res?.data[0]?.visitDate
    // console.log('visitDate: ', visitDate);
    // const visitEndDate = res?.data[0]?.visitEndDate
    // console.log('visitEndDate: ', visitEndDate);

    res.code === "ZCVI0000" ?
      message.success('操作成功') :
      // res.code === "ZCVIS0002" || res.code === "ZCVIS0012" ?
      //   message.warning(`该访客已有 ${moment(visitDate).format('YYYY-MM-DD HH:mm')} ~ ${moment(visitEndDate).format('YYYY-MM-DD HH:mm')} 访问权限，请调整时间`) :
      message.warning(res.message || "操作失败")

    res.code === "ZCVI0000" && handleClose()
    res.code === "ZCVI0000" && resetFields()
    res.code === "ZCVI0000" && actionRef.current.reload()
  };

  return (
    <div className='vis-invitation-list'>
      <ProTableCustom
        columns={columnsWithEllipsis}
        actionRef={actionRef}
        request={async ({ current: pageNumber, pageSize, ...restParams }, sort, filter) => {
          try {
            let data = [];
            let success = true;
            let total = 0;
            const res = await queryVisitorRegistrationList({
              checkStatus: [2, 3],
              pageSize,
              pageNumber,
              visitorName: restParams?.searchParams || "",
            });
            total = res?.totalCount || 0;
            data = res?.visRegistrationList || [];
            try {
              const accessGroups = await getVisLevelList({
                pageNumber: 1,
                pageSize: 999999,
                name: "",
              });
              const options = accessGroups?.accessGroups?.map(item => ({
                value: item.id,
                label: item.name,
              })) || [];
              data = data.map((e) => {
                options.forEach((o) => {
                  if (e.accessGroupIds === o.value) {
                    e.accessGroupName = o.label;
                  }
                });
                return e;
              });
            } catch (error) {
              success = false;
            }
            return {
              data,
              success,
              total,
            };
          } catch (error) {
            return {
              data: [],
              success: false,
              total: 0,
            };
          }
        }}
        searchPlaceholder={t("card.searchPlaceholder001")}
        rowKey="id"
        toolBar={[
          <Button
            key="button"
            icon={<PlusOutlined />}
            type="primary"
            onClick={handleOpen}
          >
            来访登记
          </Button>,
        ]}
      />
      {open && <VisitorDetailsDrawer
        open={open}
        items={item}
        data={selectedRow} // 将选中的表格行数据传递给DetailsDrawer
        title='详情'
        width={450}
        onClose={() => { setOpen(false); }}
      />}

      <VisitRegistrationForm

        open={registrationOpen}
        onClose={handleClose}
        onFinish={handleFinish}
      />

    </div>
  )
}



