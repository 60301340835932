import { combineReducers } from "redux";
import account from "./account";
import settings from "./settings";
import { connectRouter } from "connected-react-router";

const createRootReducer = (history)=> combineReducers({
  account,
  settings,
  router: connectRouter(history)
})

export default createRootReducer