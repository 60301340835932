import { request, payload } from "zlink-front";
import { apiVersion } from "@/enums/common";
const api = apiVersion();

export const getCaptchaImage = () =>
  request({
    url: `${api}/captchaImage`,
    method: "get",
  });

export const accountLogin = (params = {}) =>
  request({
    url: `${api}/customer/sso/login`,
    method: "post",
    noToken: true,
    ...payload(params),
    returnResultAll: true
  });

export const accountLogout = () =>
  request({
    url: `${api}/customer/sso/logout`,
    method: "get",
    hideTipError: true,
  });

// 找回密码发送验证码
export const forgotPassword = (emailId) =>
  request({
    url: `${api}/onboard/${emailId}/forgotPassword`,
    method: "post",
  });

// 找回密码
export const resetPassword = (code, params) =>
  request({
    url: `${api}/onboard/${code}/resetPassword`,
    method: "put",
    ...payload(params),
  });

// 检查用户是否已经存在,注册时点击下一步
export const verifyUser = (params) =>
  request({
    url: `${api}/onboard/${params.code}/verifyUser`,
    method: "post",
    ...payload(params),
  });

 
// 校验用户是否存在
export const verifyUserPhone = (emailOrPhone) =>
request({
  url: `${api}/onboard/user/verify/${emailOrPhone}`,
  method: "get",
  hideTipError: true
});

// 注册人员与公司发送验证码
export const getRegisterCode = (phoneId) =>
  request({
    url: `${api}/onboard/${phoneId}/registerCode`,
    method: "post",
  });

// 注册人员与公司
export const register = (params) =>
  request({
    url: `${api}/onboard/${params.code}/register`,
    method: "post",
    ...payload(params),
  });

// 修改密码发送验证码
export const getChangePasswordCode = (phoneId) =>
  request({
    url: `${api}/auth/user/${phoneId}/changePasswordCode`,
    method: "post",
  });

// 修改密码
export const changePassword = (userIdOrEmailOrPhone, params) =>
  request({
    url: `${api}/auth/user/changePassword/${userIdOrEmailOrPhone}`,
    method: "put",
    ...payload(params),
  });

// 重置密码
export const resetUserPassword = (userIdOrEmailOrPhone) =>
  request({
    url: `${api}/auth/user/resetPassword/${userIdOrEmailOrPhone}`,
    method: "put",
  });

// 获取公司下的用户
export const getCompanyUserList = (params, hideLoading = true) =>
  request({
    url: `${api}/auth/company/${params.companyIdOrCode}/user?pageNumber=${params.pageNumber}`,
    method: "get",
    hideLoading,
  });

// 获取公司下的不是owner角色的用户
export const getNotOwnerUserList = (params, hideLoading = true) =>
  request({
    url: `${api}/auth/company/${params.companyIdOrCode}/notOwnerUser?pageNumber=${params.pageNumber}`,
    method: "get",
    hideLoading,
  });

// 手机验证码登录
export const phoneLogin = (params) =>
  request({
    url: `${api}/customer/sso/login/${params.phone}/${params.code}`,
    method: "post",
    noToken: true,
  });

// 手机验证码登录-发送短信
export const sendCode = (phone) =>
  request({
    url: `${api}/customer/sso/login/sendCode/${phone}`,
    method: "post",
  });

// 获取公司规模 行业类型字典
export const getCompanyDic = (code, hideLoading = true) =>
  request({
    url: `${api}/onboard/company/dictionaryValue?code=${code}`,
    method: "get",
    hideLoading,
  });

// 用户注销
export const accountDeregistration = (params) =>
  request({
    url: `${api}/auth/user/logOut`,
    method: "post",
    ...payload(params)
  })

// 用户注销
export const getAccountDeregistrationCode = () =>
  request({
    url: `${api}/auth/user/logOutCode`,
    method: "post"
  })

// 用户退出组织验证码
export const getExitCompanyCode = (phoneId) =>
  request({
    url: `${api}/auth/exitCompanyCode/${phoneId}`,
    method: "post"
  })

// 用户退出组织验证码
export const exitCompany = (params) =>
  request({
    url: `${api}/auth/user/exitCompany`,
    method: "post",
    ...payload(params)
  })

// 获取当前用户的权限编码
export const getPermissions = (hideLoading = true) =>
  request({
    url: `${api}/auth/user/permissions`,
    method: "get",
    hideLoading,
  });
 

// 微信扫码登录
export const getWxQrCode = (params) =>
  request({
    url: `${api}/customer/sso/login/wechat/qrCode`,
    method: "post",
    ...payload(params),
    hideLoading: true
  })

// 获取微信登录信息
export const getWxLoginInfo = (params) =>
  request({
    url: `${api}/customer/sso/login/wechat/loginInfo`,
    method: "post",
    ...payload(params),
    hideLoading: true
  })