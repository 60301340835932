import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import MenuCustom from "./components/Layouts/MenuCustom";
import BreadcrumbsCustom from "./components/Layouts/BreadcrumbsCustom";
import { routeData, innerRoutes } from "./router/modules";
import {
  Routes,
  Route,
  Navigate,
  useLocation
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import { changeAntdTheme } from 'mini-dynamic-antd-theme';
import { auth } from "zlink-front";

const App = props => {
  const { t } = useTranslation()
  const [routes] = useState(routeData())
  const [routeList, setRouteList] = useState({})
  const [path, setPath] = useState('')
  const location = useLocation()
  useEffect(() => {
    const pathVal = (location.pathname.split('/'))
    const active = pathVal[1] || ''
    const idx = innerRoutes.findIndex(item => item.name === active)
    window.document.title = ((!!pathVal[1] && pathVal[1] !== 'zkbio_att') ? 
      t('router.' + pathVal[pathVal.length - 1]) : t('work.Att')) + '-' + t('common.zlink') 
    if (idx !== -1) {
      setRouteList(innerRoutes[idx] || {})
      if (innerRoutes[idx]?.children && innerRoutes[idx]?.children.length) {
        const activeIdx = innerRoutes[idx]?.children.findIndex(item => item.path === location.pathname)
        if (activeIdx !== -1) {
          setPath(innerRoutes[idx]?.children[activeIdx])
        }
      }
    } else {
      setRouteList({})
    }
  }, [location]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    changeAntdTheme(auth.getAntdPrimaryColor() || '#7AC143')
  }, [])// eslint-disable-line react-hooks/exhaustive-deps
  
  return (
    <>
      <div className="app-container">
        <div className="main-custom">
          {
            !routeList?.childrenHidden && !!routeList?.children &&
            !!routeList?.children.length && routeList.name !== 'work' &&
            <MenuCustom path={path} routeList={routeList} />
          }
          <div className={`main-custom__center ${!routeList?.meta?.hideBreadcrumbs && 'zk-pd20'}`}>
            {
              !!routeList?.children && !!routeList?.children.length &&
              !routeList?.meta?.hideBreadcrumbs &&
              <BreadcrumbsCustom path={path} routeList={routeList} />
            }
            <Routes>
              {
                routes.map(item => {
                  // 菜单权限code
                  const code = item.meta?.roleCode
                  const codeStatus = !!code ? props?.permissions?.includes(code) : true
                  return !!codeStatus  &&
                    <Route
                      key={item.path}
                      path={item.path}
                      element={ item?.element }
                      // element={item?.element}
                    />
                })
              }
              <Route path='/*' element={<Navigate to={"/notFound"} />} />
            </Routes>
          </div>
        </div>
      </div>
    </>
  )
}
const mapStateToProps = ({ account }) => ({
  accountInfo: account.accountInfo,
  token: account.token,
  permissions: account.permissions,
  orgInfo: account.orgInfo
})

export default connect(
  mapStateToProps
)(App)