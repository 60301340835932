import React, { useState, useEffect } from "react";
import "@/assets/scss/components/base-file-upload.scss";
import { Upload, Modal, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import { uploadFile, uploadFileStatic } from "@/api/sys";
import { file } from "zlink-front";
import { useTranslation } from "react-i18next";
import Resizer from "react-image-file-resizer";
import { Loading } from "zlink-front";

const { getBase64 } = file;
/**
 *
 * @param {Number} maxFiles 限制数量
 * @param {Number} maxSize 限制大小
 * @param {String} listType antd 类型
 * @param {String} accept 文件类型
 * @param {Function} add 添加
 * @param {Function} remove 删除
 * @param {Boolean} isStatic 区分云存储(false)或服务器本地(true)
 * @param {Boolean} apiStatic 接口是否静态
 * @param {Array} defaultFileList 默认
 * @param {Boolean} isUpload 是否为上传
 * @param {Boolean} disabled 是否为禁用
 * @param {String} returnType 返回类型 base64, blob or file
 * @param {Array} removeList 需要手动删除
 * 
 */

const BaseFileUpload = (props) => {
  const { t } = useTranslation();

  // 加载默认图片
  useEffect(() => {
    if (props.defaultFileList) {
      setFileList([...props.defaultFileList]);
    }
  }, [props.defaultFileList])// eslint-disable-line react-hooks/exhaustive-deps



  const [uploads, setUploads] = useState({
    previewVisible: false,
    previewImage: "",
    previewTitle: "",
    action: "",
  });

  const [fileList, setFileList] = useState([]);
  const [action, setAction] = useState("");

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>{t("upload.clickUploadFile")}</div>
    </div>
  );

  const handleCancel = () => setUploads({ ...uploads, previewVisible: false });

  const remove = (file) => {
    // 删除完成返回给调用的组件
    for (let r of fileList) {
      if (r.uid === file.uid) {
        props.remove({
          uid: r.uid,
          objectKey: r.objectKey,
        });
      }
    }
    fileList.splice(
      fileList.findIndex((item) => item.uid === file.uid),
      1
    );
    setFileList([...fileList]);
    return true;
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview && !file?.thumbUrl) {
      file.preview = await getBase64(file.originFileObj);
    }
    setUploads({
      previewImage: file?.url ?? file?.preview ?? file?.thumbUrl,
      previewVisible: true,
      previewTitle:
        file?.name ??
        file?.url?.substring(file?.url?.lastIndexOf("/") + 1) ??
        t('common.potoPreview'),
    });
  };

  const handleChange = (value) => {
    // console.log('value: ', value);
    const isJpgOrPng = value.file.type === "image/jpeg" || value.file.type === "image/png" || value.file.type === "image/jpeg";

    if (!isJpgOrPng) {
      message.error(t("device.btTip3"));
      return
    }

    if (props?.maxSize) {
      const isLt = value.file.size / 1024 / 1024 < props.maxSize;
      if (!isLt) {
        message.error(t("upload.tips4") + props.maxSize + t("upload.tips5"));
        return isLt;
      }
    }
    if (props?.maxCount && value.fileList.length > props?.maxCount) {
      message.warning(t("upload.maxNumber"));
      return;
    }
    setFileList([...value.fileList]);
  };

  const handleBeforeUpload = async (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/jpeg";
    if (!isJpgOrPng) return
    // 批量上传loading
    if (!!props?.multiple) {
      Loading.show({ type: 'linear' })
    }
    if (props.isUpload) {
      setAction("upload");
    }
  };

  // 图片尺寸及大小压缩
  const handleImageFileResizer = async (file) => {
    const { uid, name } = file.file
    try {
      await Resizer.imageFileResizer(
        file.file,
        1080,
        1080,
        "JPEG",
        80,
        0,
        (uri) => {
          let newFile = { ...file }
          newFile.file = uri
          customUploadFile(file, uid, newFile, name)
        },
        props?.returnType
      )
    } catch (err) {
      console.log(err);
    }
  }

  // const [previewImage,  setPreviewImage] = useState('')
  const customUploadFile = async (file, uid, newFile, name) => {
    // 批量上传loading
    if (!!props?.multiple && uid === fileList[fileList.length - 1].uid) {
      Loading.hidden()
    }
    // loading
    if (!props.isUpload && action === "") {
      let thumbUrl = "";
      for (let f of fileList) {
        if (f.uid === uid) {
          f.thumbUrl = props?.returnType === 'base64' ? newFile.file : await getBase64(f.originFileObj)
          thumbUrl = f.thumbUrl;
          f.status = "success";
          f.percent = 100;
          f.file = props?.returnType === 'blob' ? newFile : file
          break
        }
      }
      setFileList([...fileList]);
      // 上传完成返回给调用的组件
      for (let r of fileList) {
        if (r.uid === uid) {
          props.add({
            uid: r.uid,
            objectKey: r.objectKey,
            base64: thumbUrl,
            localFileName: name,
            file: r.file
          });
          break;
        }
      }
    } else {
      for (let f of fileList) {
        if (f.uid === uid) {
          f.status = "uploading";
        }
      }
      setFileList([...fileList])
      const formData = new FormData()
      formData.append("file", newFile?.file)
      const fileType = name?.split(".")?.pop()
      const api = props?.apiStatic ? uploadFileStatic : uploadFile
      try {
        const result = await api(
          {
            fileName: `zlink-${props.accept === "image/*" ? "img" : "file"
              }-${new Date().getTime()}.${fileType}`, //name,
            scope: "Application",
            catagory: "profilephoto",
            applicationName: "zlink",
            isStatic: props.isStatic,
          },
          formData
        );
        if (result?.objectKey) {
          let thumbUrl = "";
          for (let f of fileList) {
            if (f.uid === uid) {
              f.thumbUrl = await getBase64(f.originFileObj);
              thumbUrl = f.thumbUrl;
              f.status = "success";
              f.percent = 100;
              break;
            }
          }
          // 上传完成返回给调用的组件
          for (let r of fileList) {
            if (r.uid === uid) {
              props.add({
                uid: r.uid,
                objectKey: result.objectKey,
                base64: thumbUrl,
                localFileName: name,
              });
              break;
            }
          }
        } else {
          // 失败删除失败的列表
          message.error(t("upload.tips6") + name + t("upload.tips7"));
          fileList.splice(
            fileList.findIndex((item) => item.uid === uid),
            1
          );
        }
      } catch (error) {
        // 失败删除失败的列表
        message.error(t("upload.tips6") + name + t("upload.tips7"));
        fileList.splice(
          fileList.findIndex((item) => item.uid === uid),
          1
        );
      }
      setFileList([...fileList]);
    }
  }

  // 监听需要删除
  useEffect(() => {
    if (props?.removeList && props?.removeList.length > 0 && fileList.length > 0) {
      let all = fileList
      const filterList = all.filter(item => {
        const idx = props?.removeList.findIndex(sitem => sitem.uid === item.uid)
        return idx === -1
      })
      setFileList(filterList)
    }
  }, [props?.removeList])// eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    !!props?.multiple && props?.uploadNumChange(fileList.length)
  }, [fileList.length])// eslint-disable-line react-hooks/exhaustive-deps


  return (
    <>
      <Upload
        accept={props.accept}
        customRequest={handleImageFileResizer}
        listType="picture-card"
        fileList={fileList}
        onPreview={handlePreview}
        onChange={handleChange}
        beforeUpload={handleBeforeUpload}
        onRemove={remove}
        defaultFileList={props.defaultFileList}
        multiple={props?.multiple}
        disabled={!!props?.disabled}
      >
        {fileList.length >= props.maxFiles ? null : uploadButton}
      </Upload>
      <Modal
        open={uploads.previewVisible}
        title={uploads.previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img
          alt={t("upload.previewFail")}
          style={{ width: "100%" }}
          src={uploads.previewImage}
        />
      </Modal>
      {/* <img
        alt={t("upload.previewFail")}
        style={{ width: "100%" }}
        src={previewImage}
      /> */}
    </>
  );
};

BaseFileUpload.protoTypes = {
  accept: PropTypes.string,
  maxFiles: PropTypes.number,
  isUpload: PropTypes.bool,
  defaultFileList: PropTypes.array,
  multiple: PropTypes.bool,
};

BaseFileUpload.defaultProps = {
  accept: "audio/*,video/*,image/*", // 接收数据类型
  isUpload: false, // 是否上传至文件存储服务器
  maxFiles: 1, // 最大上传文件数量
  defaultFileList: [],
  multiple: false,
};

export default BaseFileUpload;
