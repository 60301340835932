import { request, payload } from "zlink-front";
import { apiVersion, apiAccVersion } from "@/enums/common";
const api = apiVersion()


//获取门禁时间段列表
export const getTimesegSearchList = (params, hideLoading = true) =>
  request({
    url: `${api}/acc/timeseg/search?name=${params?.name || ""}&page=${params?.pageNumber
      }&size=${params?.pageSize}`,
    method: "GET",
    hideLoading,
  });

export const getTimesegList = (params, hideLoading = true) =>
  //获取所有门禁时间段列表
  request({
    url: `${api}/acc/timeseg`,
    method: "GET",
    hideLoading,
  });

// 根据id查询时间段
export const getTimeseg = (timeSegId, hideLoading = true) =>
  request({
    url: `${api}/acc/timeseg/${timeSegId}`,
    method: "get",
    hideLoading,
  });

// 删除门禁时间段
export const deleteTimeseg = (params) =>
  request({
    url: `${api}/acc/timeseg?ids=${params.id}`,
    method: "delete",
  });

// 更新门禁时间段
export const updateTimeseg = (params) =>
  request({
    url: `${api}/acc/timeseg`,
    method: "put",
    ...payload(params),
  });

// 创建门禁时间段
export const addTimeseg = (params) =>
  request({
    url: `${api}/acc/timeseg`,
    method: "post",
    ...payload(params),
  });

//获取门禁记录
export const getAccessLevelList = (params, hideLoading = true) =>
  request({
    url: `${api}/acc/access_level?name=${params?.name || ""}&isPageable=${params?.isPageable || ""
      }&pageNumber=${params?.pageNumber || ""}&pageSize=${params?.pageSize || ""
      }`,
    method: "GET",
    hideLoading,
  });

// 删除门禁权限组
export const deleteAccessLevel = (params) =>
  request({
    url: `${api}/acc/access_level?ids=${params.id}`,
    method: "delete",
  });

// 创建门禁权限组
export const addAccessLevel = (params) =>
  request({
    url: `${api}/acc/access_level`,
    method: "post",
    ...payload(params),
  });

// 更新门禁权限组
export const updateAccessLevel = (params) =>
  request({
    url: `${api}/acc/access_level`,
    method: "put",
    ...payload(params),
  });

// 根据id查询门禁权限组
export const getAccessLevel = (id, hideLoading = true) =>
  request({
    url: `${api}/acc/access_level/${id}`,
    method: "get",
    hideLoading,
  });

//获取门禁记录列表
export const getTransactionList = (params, hideLoading = true) =>
  request({
    url: `${api}/acc/transaction/openDoor`,
    method: "POST",
    ...payload(params),
    hideLoading,
  });

//导出记录列表
export const exportTransactionList = (params) =>
  request({
    url: `${api}/acc/transaction/openDoor/export`,
    method: "POST",
    isExport: true,
    returnResultAll: true,
    ...payload(params),
  });

//获取远程开门设备列表
export const getRemoteOpenDoorList = (params) =>
  request({
    url: `${api}/acc/door/fetch`,
    method: "POST",
    hideTipError: true,
    ...payload(params),
  });

//获取远程开门设备列表
export const remoteOpenDoor = (deviceId, params, hideLoading = true) =>
  request({
    url: `${api}/acc/door/operation/${deviceId}`,
    method: "POST",
    ...payload(params),
    hideLoading,
  });

//获取异常开门记录
export const getSearchExceptionList = (params, hideLoading = true) =>
  request({
    url: `${api}/acc/transaction/exception`,
    method: "POST",
    ...payload(params),
    hideLoading,
  });

//导出异常开门记录
export const exportExceptionTransactionList = (params) =>
  request({
    url: `${api}/acc/transaction/exception/export`,
    method: "POST",
    isExport: true,
    returnResultAll: true,
    ...payload(params),
  });

// 创建临时密码
export const addTempPwd = (params) =>
  request({
    url: `${api}/acc/tempPwd/create`,
    method: "post",
    ...payload(params),
  });

// 删除临时密码
export const deleteTempPwd = (params) =>
  request({
    url: `${api}/acc/tempPwd/delete`,
    method: "delete",
    data: params,
  });

// 更新临时密码
export const updateTempPwd = (params) =>
  request({
    url: `${api}/acc/tempPwd/update/${params.id}`,
    method: "put",
    ...payload(params),
  });

// 搜索列表
export const getTempPwdList = (params, hideLoading = true) =>
  request({
    url: `${api}/acc/tempPwd/search`,
    method: "post",
    hideLoading,
    ...payload(params),
  });

// 根据id查询临时密码
export const getTempPwd = (id, hideLoading = true) =>
  request({
    url: `${api}/acc/tempPwd/${id}`,
    method: "get",
    hideLoading,
  });
export const invalidTempPwd = (params) =>
  request({
    url: `${api}/acc/tempPwd/invalid/${params.id}`,
    method: "post",
    ...payload(params),
  });

// 20220826 门禁权限组接口
//获取门禁权限组列表
export const getAccLevelList = (params, hideLoading = true) =>
  request({
    url: `${api}/acc/level/list`,
    method: "post",
    ...payload(params),
    hideLoading
  });

// 删除门禁权限组
export const deleteAccLevel = (levelId) =>
  request({
    url: `${api}/acc/level/${levelId}`,
    method: "delete",
  });

// 创建门禁权限组
export const addAccLevel = (params) =>
  request({
    url: `${api}/acc/level`,
    method: "post",
    ...payload(params),
  });

// 更新门禁权限组
export const updateAccLevel = (levelId, params) =>
  request({
    url: `${api}/acc/level/${levelId}`,
    method: "put",
    ...payload(params),
  });

// 根据id查询门禁权限组
export const getAccLevel = (levelId) =>
  request({
    url: `${api}/acc/level/${levelId}`,
    method: "get"
  });

// 权限组删除人员
export const deletePerson = (params) =>
  request({
    url: `${api}/acc/level/deletePerson`,
    method: "delete",
    ...payload(params),
  });

// 获取权限组已存在的人员列表
export const getPersonList = (params, hideLoading = true) =>
  request({
    url: `${api}/acc/level/personList`,
    method: "post",
    ...payload(params),
    hideLoading
  });

// 权限组添加人员
export const addPerson = (params) =>
  request({
    url: `${api}/acc/level/addPerson`,
    method: "post",
    ...payload(params),
  });

// 权限组删除门
export const deleteDoor = (params) =>
  request({
    url: `${api}/acc/level/deleteDoor`,
    method: "delete",
    ...payload(params),
    returnResultAll: true,
  });

// 获取权限组已存在的门列表
export const getDoorList = (params, hideLoading = true) =>
  request({
    url: `${api}/acc/level/doorList`,
    method: "post",
    ...payload(params),
    hideLoading
  });

// 权限组添加人员
export const addDoor = (params) =>
  request({
    url: `${api}/acc/level/addDoor`,
    method: "post",
    ...payload(params),
  });

// 获取门列表
export const getAllDoorList = (params) =>
  request({
    url: `${api}/customer/dcc/device/doorList`,
    method: "post",
    ...payload(params),
  });

export const getSchedulesList = (params, hideLoading = true) =>
  request({
    url: `${api}/acc/schedules/list`,
    method: "POST",
    hideLoading,
    ...payload(params),
  });

// 根据id查询时间段
export const getSchedules = (schedulesId, hideLoading = true) =>
  request({
    url: `${api}/acc/schedules/${schedulesId}`,
    method: "get",
    hideLoading,
  });

// 删除门禁时间段
export const deleteSchedules = (params) =>
  request({
    url: `${api}/acc/schedules/${params.id}`,
    method: "delete",
  });

// 更新门禁时间段
export const updateSchedules = (id, params) =>
  request({
    url: `${api}/acc/schedules/${id}`,
    method: "put",
    ...payload(params),
  });

// 创建门禁时间段
export const addSchedules = (params) =>
  request({
    url: `${api}/acc/schedules`,
    method: "post",
    ...payload(params),
  });

// 初始化门禁时间段
export const addInitSchedules = (params) =>
  request({
    url: `${api}/acc/schedules/initSchedules`,
    method: "post",
    ...payload(params),
  });

// ----------------访客权限组------------------
// 创建权限组
export const addVisLevel = (params) => {
  params.tag = 'VIS'
  return request({
    url: `${api}/acc/level`,
    method: "post",
    ...payload(params),
  });
}

//获取权限列表
export const getVisLevelList = (params, hideLoading = true) => {
  params.tag = 'VIS'

  return request({
    url: `${api}/acc/level/list`,
    method: "post",
    ...payload(params),
    hideLoading
  });
}

// 更新权限
export const updateVisLevel = (levelId, params) => {
  params.tag = 'VIS'
  return request({
    url: `${api}/acc/level/${levelId}`,
    method: "put",
    ...payload(params),
  });
}

// 删除权限
export const deleteVisLevel = (levelId) =>
  request({
    url: `${api}/acc/level/${levelId}`,
    returnResultAll: true,
    method: "delete",
  });






