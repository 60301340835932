import { request, payload } from "zlink-front";
import { apiVersion } from "@/enums/common";
const api = apiVersion();

// 获取设备列表
export const getDeviceInfo = (deviceId) =>
  request({
    url: `${api}/customer/dcc/device/${deviceId}`,
    method: "get",
  });

// 获取设备列表
export const fetchDevice = (params) =>
  request({
    url: `${api}/customer/dcc/device/list`,
    method: "post",
    ...payload(params),
  });

// 通过sn删除设备
export const deleteDevice = (deviceId) =>
  request({
    url: `${api}/customer/dcc/device/${deviceId}`,
    method: "delete",
  });

// 绑定全部
export const bindDevicePersonAll = (params) =>
  request({
    url: `${api}/customer/dcc/device/person/saveAll`,
    method: "post",
    ...payload(params),
  });

// --设备绑定人员接口--

// 保存设备和人员的关系
export const bindDevicePerson = (params) =>
  request({
    url: `${api}/customer/dcc/device/person/save`,
    method: "post",
    ...payload(params),
  });

//通过设备sn获取已绑定的人员
export const getDeviceBindPerson = (sn) =>
  request({
    url: `${api}/customer/dcc/device/person/bindPersonList/${sn}`,
    method: "get",
  });

// 通过设备sn删除已绑定的人员
export const deleteDevicePerson = (sn, params) =>
  request({
    url: `${api}/customer/dcc/device/person/delete/${sn}`,
    method: "delete",
    ...payload(params),
  });

// --设备绑定部门接口--

// 保存设备和部门的关系
export const bindDeviceDept = (params) =>
  request({
    url: `${api}/customer/dcc/device/dept/save`,
    method: "post",
    ...payload(params),
  });

//通过设备sn获取已绑定的部门
export const getDeviceBindDept = (sn) =>
  request({
    url: `${api}/customer/dcc/device/dept/bindDeptList/${sn}`,
    method: "get",
  });

// 通过设备sn删除已绑定的部门
export const deleteDeviceDept = (sn, params) =>
  request({
    url: `${api}/customer/dcc/device/dept/delete/${sn}`,
    method: "delete",
    ...payload(params),
  });

// 通过sn获取设备信息
export const getSnDevice = (sn) =>
  request({
    url: `${api}/customer/dcc/device/fetch/${sn}`,
    method: "get",
  });

export const getSnDeviceBasic = (sn) =>
  request({
    url: `${api}/customer/dcc/device/basic/info?sn=${sn}`,
    method: "get",
  });

// 全员相关接口
//删除全员
export const deleteAllPerson = (sn, params) =>
  request({
    url: `${api}/customer/dcc/device/person/deleteAllPerson/${sn}`,
    method: "delete",
    ...payload(params),
  });

// 获取全员
export const getAllPerson = (sn) =>
  request({
    url: `${api}/customer/dcc/device/person/getAllPerson/${sn}`,
    method: "get",
  });

// 通过设备sn查看设备人员
export const getDevicePerson = (params) =>
  request({
    url: `${api}/customer/dcc/device/person/getDevicePerson/${params.sn}?pageNumber=${params.pageNumber}&pageSize=${params.pageSize}&firstName=${params?.firstName}`,
    hideLoading: true,
    method: "get",
  });

// 获取公司的设备数量
export const getDeviceCount = (companyCode, hideTipError = true) =>
  request({
    url: `${api}/customer/dcc/device/getDeviceCount/${companyCode}`,
    method: "get",
    hideTipError,
  });

// 获取运维服务
export const getOperationService = (companyCode, hideTipError = false) =>
  request({
    url: `${api}/partner/auth/company/getOperationService/${companyCode}`,
    method: "post",
    hideTipError,
  });

// 创建生物模板
export const addBioTemplate = (params) =>
  request({
    url: `${api}/org/bioTemplate`,
    method: "post",
    ...payload(params),
  });

// 删除
export const deleteBioTemplate = (employeeCode) =>
  request({
    url: `${api}/org/bioTemplate/${employeeCode}`,
    method: "delete",
  });

// 通过sn授权设备
export const authorizeDevice = (sn) =>
  request({
    url: `${api}/customer/dcc/device/authorize/${sn}`,
    method: "post",
  });

// 通过sn绑定设备
export const deviceBindCompany = (params) =>
  request({
    url: `${api}/customer/dcc/device/bind/company`,
    method: "post",
    ...payload(params),
  });

// 更改设备参数
export const updateDevice = (id, params) =>
  request({
    url: `${api}/customer/dcc/device/updateDevice/${id}`,
    method: "put",
    ...payload(params),
  });

// 更改设备参数
export const rebootDevice = (id, params) =>
  request({
    url: `${api}/customer/dcc/device/reboot/${id}`,
    method: "post",
    ...payload(params),
  });


// 从设备删除人员信息
export const delDevicePerson = (params) =>
  request({
    url: `${api}/customer/dcc/device/person/delDevicePerson`,
    method: "post",
    ...payload(params),
  });

// 下发人员信息到设备
export const issueDevicePerson = (params) =>
request({
  url: `${api}/customer/dcc/device/person/issueDevicePerson`,
  method: "post",
  ...payload(params),
});