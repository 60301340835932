import React, { useState, useEffect } from "react";
import { Drawer, Form, Input, Select, Button } from "antd";
import { layout } from "zlink-front";
import SelectDeviceModal from "@/components/SelectDeviceModal/Index";
import { getRemoteOpenDoorList } from "@/api/acc";
import { fetchDevice } from "@/api/device";

const { defaultFormLayout } = layout;

export default function PermissionGroupEditing({ open, onClose, onSubmit, initialValues, getDelList }) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [doorloading, setDoorloading] = useState(false);
  const [num, setValue] = useState(0);

  useEffect(() => {
    if (initialValues.type === 'add') {// 新增的话就不需要存删除的设备数组
      getDelList([])
      return
    }
    const selectList = form.getFieldValue("doorIds") || []; // 已选中的门id

    const allList = selectData.devices.map((e) => e.doorId) || [];// 所有的门id
    const complementList = allList.filter((value) => !selectList.includes(value));// 求补集，相当于需要删除的门id


    getDelList(complementList)
  }, [form.getFieldValue("doorIds"), num]);

  const onDeselect = () => {
    setValue(num + 1)
  }

  const [device, setDevice] = useState({
    device: false,
  });
  const [selectData, setSelectData] = useState({
    devices: [],
  });
  useEffect(() => {
    setDoorloading(true)
    getRemoteOpenDoorList({
      pageNumber: 1,
      pageSize: 100,
    }).then((res) => {
      setSelectData({ devices: res?.doors || [] })
    }).finally(() => {
      setDoorloading(false)
    })
  }, []);

  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue(initialValues);
    }
  }, [initialValues]);

  // 表单提交函数
  const handleSubmit = () => {
    form.validateFields()
      .then((values) => {
        setLoading(true);
        onSubmit(values)
          .finally(() => setLoading(false));
      })
      .catch((err) => {

      });
  };

  const handleReset = () => {
    form.resetFields(); // 重置表单数据
    getDelList([]); // 重置删除的设备数组
  };
  // 选择设备参数
  const onModalChange = (value, type) => {
    const ids = value.map((e) => e.doorId)
    // const doorIds = form.getFieldValue("doorIds") || []
    // const data = Array.from(new Set([...ids, ...doorIds]))
    const data = Array.from(new Set([...ids]))
    form.setFieldsValue({
      doorIds: data
    })
  };


  const setDeviceVisibleFn = () => {
    setDevice({
      device: false,
    });
  };

  return (
    <Drawer
      title={initialValues.type === 'set' ? "编辑权限组" : "新增权限组"}
      open={open}
      onClose={onClose}
      width={500}
      destroyOnClose
    >
      <Form
        {...defaultFormLayout.form}
        form={form}
        onFinish={handleSubmit}
        layout="Horizontal"
        initialValues={initialValues}
      >
        <Form.Item
          name="name"
          label="权限组名称"
          rules={[
            { required: true, message: "请输入权限组名称" },
            { max: 20, message: "权限组名称最多输入20个字" },

          ]}
        >
          <Input placeholder="请输入权限组名称" />
        </Form.Item>
        {doorloading ?
          <Form.Item
            // name="doorIds"
            label="通行设备"
            rules={[{ required: false, message: "请选择通行设备" }]}
          >
            <Input placeholder='加载中...' />
          </Form.Item>
          :
          <Form.Item
            name="doorIds"
            label="通行设备"
            rules={[{ required: false, message: "请选择通行设备" }]}
          >
            <Select mode="multiple" placeholder="请选择通行设备"
              options={selectData.devices.map((e) => {
                return {
                  label: e.deviceAlias,
                  value: e.doorId,
                }
              })}
              onDeselect={onDeselect}
              open={false}
              onClick={() => {
                setDevice({
                  device: true,
                });
              }}
            >
            </Select>
          </Form.Item>}

        <Form.Item style={{ marginLeft: '102px' }}>
          <Button type="ghost" onClick={handleReset} style={{ marginRight: '15px' }}>
            重置
          </Button>

          <Button type="primary" htmlType="submit" loading={loading}>
            提交
          </Button>
        </Form.Item>
        {/* <Form.Item>

        </Form.Item> */}
      </Form>

      {/* 选择设备 */}
      {device.device && (
        <SelectDeviceModal
          visible={device.device}
          onChange={onModalChange}
          close={setDeviceVisibleFn}
          type="door"
          selectedKeys={form.getFieldValue("doorIds") || []}
          fetchDevice={fetchDevice}
          getRemoteOpenDoorList={getRemoteOpenDoorList}
          filter={false}
          required={false}
        />
      )}

    </Drawer>
  );
}
