import React, { useRef, useState } from 'react'
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import { ProTableCustom } from "zlink-front";
import "@/assets/scss/vis.scss";
import useHorizontalScroll from '../../../hook/useHorizontalScroll'
import VisitorDetailsDrawer from '@/components/VisitorDetailsDrawer'
import { getVisLevelList } from "@/api/acc";

import { queryVisitorInvitationList } from "@/api/vis";

import moment from 'moment';


export default function index() {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { t } = useTranslation();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const actionRef = useRef();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [open, setOpen] = useState(false);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useHorizontalScroll('ant-table-body', 300);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [selectedRow, setSelectedRow] = useState({}); // 存储选中的表格行数据

  const columns = [
    {
      title: t("访客姓名"),
      key: "visitorName",
      dataIndex: "visitorName",
      hideInSearch: true,
      fixed: 'left',
      width: 150,
      render: (_, r) => r.visitorName || '-'

    },
    {
      title: t("访客手机号"),
      key: "visitorPhone",
      dataIndex: "visitorPhone",
      hideInSearch: false,
      fixed: 'left',
      width: 120,
      render: (_, r) => {
        const phone = r.visitorPhone.replace('+86-', '');
        return phone;
      }
    },

    {
      title: t("访客所属公司"),
      key: "visitorCompanyName",
      dataIndex: "visitorCompanyName",
      hideInSearch: false,
      width: 200,
      render: (_, r) => r.visitorCompanyName || '-'
    },
    {
      title: t("预约来访时间"),
      key: "visitDate",
      dataIndex: "visitDate",
      hideInSearch: false,
      width: 200,
      render: (_, r) => {
        return r.visitDate ? moment(r.visitDate).format('YYYY-MM-DD HH:mm') : '-'
      }
    },
    {
      title: t("预约结束时间"),
      key: "visitEndDate",
      dataIndex: "visitEndDate",
      hideInSearch: false,
      width: 200,
      render: (_, r) => {
        return r.visitEndDate ? moment(r.visitEndDate).format('YYYY-MM-DD HH:mm') : '-'
      }
    },
    {
      title: t("被访人姓名"),
      key: "visitEmpName",
      dataIndex: "visitEmpName",
      hideInSearch: false,
      width: 200

    },
    {
      title: t("来访状态"),
      key: "reservationStatus",
      dataIndex: "reservationStatus",
      hideInSearch: false,
      width: 200,
      render: (_, r) => {
        /*** 1 待接受 2 邀请接受 3 邀请拒绝 4 已来访 5撤销 6已签退 7已失效 **/
        const checkStatusMap = {
          1: '待接受',
          2: '未来访',
          3: '已拒绝',
          4: '已到访',
          5: '已撤销',
          6: '已签离',
          7: '已失效',
        }
        return checkStatusMap[r.reservationStatus] || '-'
      }
    },
    {
      title: t("来访事由"),
      key: "visitReason",
      dataIndex: "visitReason",
      hideInSearch: false,
      width: 300,
      render: (_, r) => r.visitReason || '-'

    },
    {
      title: t("操作"),
      key: "operation",
      dataIndex: "operation",
      hideInSearch: false,
      fixed: 'right',
      width: 100,
      render: (_, record) => {
        return <Button type="link" onClick={() => handleOpenDetails(record)}>
          {t('查看详情')}
        </Button>
      }
    },
  ];
  const handleOpenDetails = (value) => {
    const record = { ...value }
    const verifyModeObj = {
      1: '密码',
      2: '二维码',
      3: '面部照片',
    }
    /** 验证方式 1:密码、2:二维码、3:人脸*/
    record.verify = verifyModeObj[record.verifyMode]

    record.checkInDate = record.checkInDate ? moment(record.checkInDate).format('YYYY-MM-DD HH:mm') : '-'
    record.checkOutDate = record.checkOutDate ? moment(record.checkOutDate).format('YYYY-MM-DD HH:mm') : '-'
    record.visitEndDate = record.visitEndDate ? moment(record.visitEndDate).format('YYYY-MM-DD HH:mm') : '-'
    record.visitDate = record.visitDate ? moment(record.visitDate).format('YYYY-MM-DD HH:mm') : '-'



    setSelectedRow(record); // 更新选中的表格行数据
    setOpen(true); // 打开DetailsDrawer
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const columnsWithEllipsis = columns.map(column => {
    const { render, ...rest } = column;
    const key = column.dataIndex
    return {
      ...rest,
      ellipsis: true,
      render: (_, record) => {
        const text = record[key]
        if (render) return render(_, record)
        if (!render) return <span title={text}>{text}</span>
      }
    };
  });

  return (
    <div className='vis-invitation-list'>
      <ProTableCustom
        columns={columnsWithEllipsis}
        actionRef={actionRef}
        request={async ({ current: pageNumber, pageSize, ...restParams }, sort, filter) => {
          const res = await queryVisitorInvitationList({
            pageSize: pageSize,
            pageNumber: pageNumber,
            visitorName: restParams?.searchParams || "",
          });
          let accessGroups = await getVisLevelList({
            pageNumber: 1,
            pageSize: 999999,
            name: "",
          })
          const options = accessGroups.accessGroups.map(item => ({
            value: item.id,
            label: item.name,
          }));
          let data = res.visReservationList.map((e) => {
            options.forEach((o) => {
              if (e.accessGroupIds === o.value) {
                e.accessGroupName = o.label
              }
            });
            return e
          }) || []
          return {
            data: data,
            success: true,
            total: res.totalCount,
          };
        }}
        searchPlaceholder={t("card.searchPlaceholder001")}
        rowKey="id"
        toolBar={[]}
      />
      <VisitorDetailsDrawer
        open={open}
        data={selectedRow} // 将选中的表格行数据传递给DetailsDrawer
        title='详情'
        onClose={() => { setOpen(false); }}
      />
    </div>
  )
}



