import { useEffect } from 'react';

// 自定义 Hook 用于实现水平滚动并将滚动条回调到原位
function useHorizontalScroll(className, time = 10) {
  // 在组件加载或 className 发生变化时执行
  useEffect(() => {
    // 获取指定类名的元素
    const element = document.querySelector(`.${className}`);
    if (element) {
      // 延迟time秒后执行滚动操作
      const timeoutId = setTimeout(() => {
        // 水平滚动1个像素
        element.scrollBy(1, 0);

        // 在time秒后将滚动条回调到原位
        setTimeout(() => {
          element.scrollLeft = 0;
        }, time);
      }, time);

      // 返回一个清除定时器的函数，以确保在组件卸载或下一次滚动之前清除定时器
      return () => clearTimeout(timeoutId);
    }
  }, [className, time]);
}

export default useHorizontalScroll;
