import { request, payload } from "zlink-front";
import { apiVersion } from "@/enums/common";
const api = apiVersion();

//获取公司列表 token 1（未切换公司）
export const getCompaniesList = (params) =>
  request({
    url: `${api}/auth/user/companies`,
    method: "post",
    ...payload(params),
  });
  
//获取公司列表 token 2 （切换公司后）
export const getCompanyList = (params) =>
  request({
    url: `${api}/auth/company`,
    method: "post",
    ...payload(params),
  });

// 切换公司
export const switchCompany = (params) =>
  request({
    url: `${api}/auth/user/switchCompany`,
    method: "put",
    ...payload(params),
  });

//获取组织架构树
export const getDepartmentTree = (params, hideLoading = true) =>
  request({
    url: `${api}/org/department/treeNode`,
    method: "get",
  });

//获取组织架构树
export const getDepartmentTreeReport = (params, hideLoading = true) =>
  request({
    url: `${api}/org/department/treeReportNode`,
    method: "get",
    hideLoading
  });

//获取组织架构列表
export const getDepartmentList = (hideLoading = true) =>
  request({
    url: `${api}/org/department/tree`,
    method: "get",
    hideTipError: true,
    hideLoading,
  });

//获取组织架构树
export const getDepartment = (departmentIdOrCode, hideLoading = true) =>
  request({
    url: `${api}/org/department/${departmentIdOrCode}`,
    method: "get",
    hideLoading,
  });

// 新增组织架构
export const addDepartment = (params) =>
  request({
    url: `${api}/org/department`,
    method: "post",
    data: params,
  });

// 更新组织架构
export const updateDepartment = (id, params) =>
  request({
    url: `${api}/org/department/${id}`,
    method: "put",
    ...payload(params),
  });

// 删除组织架构
export const deleteDepartment = (params) =>
  request({
    url: `${api}/org/department`,
    method: "delete",
    ...payload(params),
  });

// 获取职位列表
export const getPositionList = (params, hideLoading = true) =>
  request({
    url: `${api}/org/designation?pageNumber=${params.pageNumber}&pageSize=${
      params.pageSize
    }&name=${params.name || ""}&code=${params.code || ""}`,
    method: "get",
    hideLoading,
    ...payload(params),
  });

// 获取职位列表-带人员统计
export const getDesignationReportList = (params, hideLoading = true) =>
  request({
    url: `${api}/org/designation/report?pageNumber=${
      params.pageNumber
    }&pageSize=${params.pageSize}&name=${params.name || ""}&code=${
      params.code || ""
    }`,
    method: "get",
    hideLoading,
    ...payload(params),
  });

// 获取职位
export const getPosition = (designationIdOrCode, hideLoading = true) =>
  request({
    url: `${api}/org/designation/${designationIdOrCode}`,
    method: "get",
    hideLoading,
  });

// 新增职位
export const addPosition = (params) =>
  request({
    url: `${api}/org/designation`,
    method: "post",
    data: params,
  });

// 更新职位
export const updatePosition = (designationId, params) =>
  request({
    url: `${api}/org/designation/${designationId}`,
    method: "put",
    ...payload(params),
  });

// 删除职位
export const deletePosition = (params) =>
  request({
    url: `${api}/org/designation`,
    method: "delete",
    data: params,
  });

// 查询成员列表
export const getEmployeeList = (params, hideLoading = true) =>
  request({
    url: `${api}/org/employee/search`,
    method: "post",
    hideLoading,
    ...payload(params),
  });

// 查询成员
export const getEmployee = (id, toggleKeyToURL = true) =>
  request({
    url: `${api}/org/employee/${id}?toggleKeyToURL=${toggleKeyToURL}`,
    method: "get"
  });

// 删除成员
export const deleteEmployee = (params) =>
  request({
    url: `${api}/org/employee`,
    method: "delete",
    ...payload(params),
  });

// 新增成员
export const addEmployee = (formData, hideLoading = true) =>
  request({
    url: `${api}/org/employee`,
    method: "post",
    hideLoading,
    // ...payload(params),
    data:formData
  });

// 新增成员激活短信
export const employeeActivate = (params) =>
  request({
    url: `${api}/org/employee/activate`,
    method: "post",
    data: params,
  });

// 新增成员(数组)
export const addEmployees = (params) =>
  request({
    url: `${api}/org/employee`,
    method: "post",
    ...payload(params),
  });

// 更新成员
export const updateEmployee = (
  employeeIdOrEmployeeCode,
  formData,
  hideLoading = true
) =>
  request({
    url: `${api}/org/employee/${employeeIdOrEmployeeCode}`,
    method: "put",
    hideLoading,
    // ...payload(params),
    data:formData
  });

// 成员激活
export const confirmActiveEmployee = (params) =>
  request({
    url: `${api}/org/employee/confirmActivation`,
    method: "put",
    ...payload(params),
  });

// 成员拒绝激活
export const refuseActiveEmployee = (secretKey) =>
  request({
    url: `${api}/org/employee/refuseActivation/${secretKey}`,
    method: "delete",
  });

// 邀约人员列表
export const getInviteEmployeeList = (params, hideLoading = true) =>
  request({
    url: `${api}/org/employee/listApply?pageNumber=${params.pageNumber}&pageSize=${params.pageSize}&nameOrPhone=${params.nameOrPhone || ""}`,
    method: "get",
    hideLoading,
    ...payload(params)
  });

// 获取邀约信息
export const getInviteInfo = () =>
  request({
    url: `${api}/org/employee/invite`,
    method: "get"
  });

// 获取邀约信息验证码
export const getInviteEmpPhoneCode = (phoneId) =>
  request({
    url: `${api}/org/employee/apply/${phoneId}/applyEmpPhoneCode`,
    method: "post"
  });

// 获取员工激活验证码
export const getActivateEmpVerifyCode = (phoneId) =>
  request({
    url: `${api}/org/employee/activate/${phoneId}/activateEmpVerifyCode`,
    method: "post",
  });

// 邀约激活
export const addInviteEmp = (params) =>
  request({
    url: `${api}/org/employee/apply`,
    method: "post",
    ...payload(params),
  });

// 邀约激活通过0、拒绝1
export const changeInviteEmpResult = (params) =>
  request({
    url: `${api}/org/employee/reviewApply/${params.id}/${params.reviewResult}`,
    method: "post",
  });

// 未激活员工列表
export const searchNoActive = (
  params,
  hideTipError = false,
  hideLoading = true
) =>
  request({
    url: `${api}/org/employee/searchNoActive`,
    method: "post",
    hideTipError: hideTipError,
    hideLoading,
    ...payload(params),
  });

// 已激活员工列表
export const searchActive = (
  params,
  hideTipError = false,
  hideLoading = true
) =>
  request({
    url: `${api}/org/employee/searchActive`,
    method: "post",
    hideTipError: hideTipError,
    hideLoading,
    ...payload(params),
  });

//baidumap 地址转经纬度
export const getGeocoding = (address) =>
  request({
    url: `/geocoding/v3/?address=${address}&output=json&ak=GTrnXa5hwXGwgQnTBG28SHBubErMKm3f`,
    method: "get",
    returnResultAll: true,
  });

// 转移组织验证码
export const transferOwnerCode = (phoneId) =>
  request({
    url: `${api}/auth/user/transferOwnerCode`,
    method: "post",
  });

// 转移组织验证码
export const transferOwner = (params) =>
  request({
    url: `${api}/auth/user/transfer/owner`,
    method: "post",
    ...payload(params),
  });

// 注销组织验证码
export const cancellationCode = (phoneId) =>
  request({
    url: `${api}/auth/cancellationCode/company/${phoneId}`,
    method: "post"
  }); 

// 注销组织
export const cancellationCompany = (params) =>
  request({
    url: `${api}/auth/cancellation/company`,
    method: "post",
    ...payload(params),
  });

// 删除离职人员
export const deleteResign = (params) =>
  request({
    url: `${api}/org/employee/resign`,
    method: "delete",
    ...payload(params)
  });

// 获取离职人员列表
export const searchResignEmployee = (params) =>
  request({
    url: `${api}/org/employee/searchResignEmployee`,
    method: "post",
    ...payload(params),
    hideLoading: true
  });

// 离职
export const updataEmpResign = (id, params) =>
  request({
    url: `${api}/org/employee/resign/${id}`,
    method: "put",
    ...payload(params)
  });

// 复职
export const updataEmpReinstatement = (id, params) =>
  request({
    url: `${api}/org/employee/reinstatement/${id}`,
    method: "put",
    ...payload(params)
  });

  
// 获取待加入公司列表
export const getNoJoinCompaniesList = (params) =>
  request({
    url: `${api}/org/employee/noJoinCompanyList`,
    method: "post",
    ...payload(params),
  });

// 员工确认加入公司
export const confirmJoinCompany = (params) =>
  request({
    url: `${api}/org/employee/confirmJoinCompany`,
    method: "post",
    ...payload(params),
  });

// 此功能执行获取所有部门树的过程(父子结构 包括统计数据、人员数据)
export const getTreeReportEmpNode = (nodeNumber, empName = '', deptId = '', hideLoading = true) =>
  request({
    url: `${api}/org/department/treeReportEmpNode?nodeNumber=${nodeNumber}&empName=${empName}&deptId=${deptId}`,
    method: "get",
    hideLoading
  })