import { request, payload } from "zlink-front";
import { apiVersion, apiVisVersion } from "@/enums/common";

const api = apiVersion();


// 获取访客授权列表
export const getVisAuthList = (params) => request({
  url: `${api}/vis/auth?pageNumber=${params.pageNumber}&pageSize=${params.pageSize}&visName=${params.visName}`,
  method: 'get'
})

// 新增访客授权
export const addVisAuth = (params) =>
  request({
    url: `${api}/vis/auth`,
    method: "post",
    data: params,
  });

// 获取访客授权
export const getVisAuth = (visitorAuthId) =>
  request({
    url: `${api}/vis/auth/${visitorAuthId}`,
    method: "get",
  });

// 修改访客授权
export const updateVisAuth = (visitorAuthId, params) => request({
  url: `${api}/vis/auth/${visitorAuthId}`,
  method: 'put',
  ...payload(params)
})


// /api/v1.0/zlink/vis/registration/page 查询访客登记列表

export const queryVisitorRegistrationList = (params) =>
  request({
    url: `${api}/vis/registration/page`,
    method: "post",
    hideLoading: true,
    data: params,
  });


// ​/api​/v1.0​/zlink​/vis​/registration​/create 此功能执行创建访客登记的过程。


export const createVisitorRegistration = (formData) =>
  request({
    url: `${api}/vis/registration/create`,
    method: "post",
    returnResultAll: true,
    data: formData
  });


// /api/v1.0/zlink/vis/registration/signOut/{visRegistrationId} 此功能执行访客邀约签退过程
export const signOut = (visRegistrationId, params) => {
  return request({
    url: `${api}/vis/registration/signOut/${visRegistrationId}`,
    method: "put",
    // ...payload(params),
  });
}

// /api/v1.0/zlink/vis/reservation/page 查询访客邀约列表

export const queryVisitorInvitationList = (params) =>
  request({
    url: `${api}/vis/reservation/page`,
    method: "post",
    hideLoading: true,
    data: params,
  });
// 
export const getVisitorArrivalRecord = (params, hideLoading = true) =>
  request({
    url: `${api}/vis/registration/visit/history`,
    method: "POST",
    ...payload(params),
    hideLoading,
  });