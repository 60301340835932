import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Modal, message } from "antd";
import "./device.scss";
// import { fetchDevice } from "@/api/device";
// import { getRemoteOpenDoorList } from "@/api/acc";
import { connect } from "react-redux";
import { ProTableCustom } from "zlink-front";
const DEVICE_TYPE = {
  'acc': 'device.acc',
  'att': 'device.att',
  'cam': 'device.cam'
}

/**
 * 设备组件参数说明
 * @param {Boolean} visible  // 弹窗展示/隐藏
 * @param {Function} onChange // 点击确认回调事件
 * @param {Function} close // 关闭窗口
 * @param {string} type  // 类型： 门列表还是设备列表, 默认设备
 * 
 * @param {string} filterKey  // 过滤类型类型： doorId:门列表, id:设备id, deviceSn: 访客预约
 *
 * @param {Object} params={ // 可选参数
 *         limit 限制可选择数量
 *         deviceType 接口设备类型 不传默认 'att,acc'
 *         key: 返回数据字段集合
 *        }
 * @param {Boolean} filter  // 是否过滤
 * @param {Array} selectedKeys  // 已选
 * @param {Array} selecteds  // 已选
 * @param {Boolean} required  // 是否必选
 * 
 * 
 */


const SelectDeviceModal = (props) => {
  const { visible, onChange, filterKey, selectedKeys, filter = true, selecteds, required = true} = props
  const { t } = useTranslation()
  const [show, setShow] = useState(false)
  const [tableData, setTableData] = useState([])
  const [selectedRows, setSelectedRows] = useState([])
  const actionRef = useRef()

  // 表格列参数
  const deviceTableColumns = [
    {
      dataIndex: "deviceAlias",
      title: "设备名称",
      ellipsis: true
    },
    {
      dataIndex: "sn",
      title: "序列号",
      ellipsis: true
    },
    {
      dataIndex: "deviceType",
      title: "设备用途",
      render: (_, record) => {
        return <>{t(DEVICE_TYPE[record?.deviceType]) || "-"}</>;
      }
    }
  ]

  const doorTableColumns = [
    {
      dataIndex: "deviceAlias",
      title: "设备名称",
      ellipsis: true
    },
    {
      dataIndex: "deviceSn",
      title: "序列号",
      ellipsis: true
    }
  ]

  // 监听父组件 弹窗显示数据
  useEffect(() => {
    handleFetchDeviceData()
    setShow(visible)
    if(selecteds) {
      setSelectedRows(selecteds)
    }
  }, [visible]) // eslint-disable-line react-hooks/exhaustive-deps

  // 关闭弹窗
  const onClose = () => {
    setShow(false)
    props.close()
  }

  const handleFetchDeviceData = async (num = 1, arr = []) => {
    try {
      const params = {
        pageNumber: num,
        pageSize: 100
      }
      if (!!props?.params?.deviceType) {
        params.deviceType = props?.params?.deviceType
      }

      let res = props.type === "door" ? await props?.getRemoteOpenDoorList(params) : await props?.fetchDevice(params)
      const newArr = [...arr, ...(props.type === "door" ? res?.doors : res?.devices)]
      if(res?.totalCount > newArr.length) {
        handleFetchDeviceData(num + 1, newArr)
      } else {
        handleFetchDevice(newArr)
      }
    } catch (e) {
      console.log(e)
    }   
  }
  const handleFetchDevice = async (list) => {
    if (list?.length) {
      let arr = []
      if (filterKey === 'deviceSn') {
        arr = !!selectedKeys && filter ? list.filter(item => 
          selectedKeys.findIndex(i => i.deviceSn === item.sn) === -1
        ) : list
      } else {
        arr = !!selectedKeys && filter ? list.filter(item => 
          !selectedKeys.includes(props.type === "door" ? item.doorId : item.id)
        ) : list
      }
      setTableData(arr)
      actionRef.current.reload()
    }
  };


  // 确认提交
  const onSubmit = () => {
    if (!!required && selectedRows.length === 0) {
      message.warning("请选择设备");
      return;
    }
    let res = selectedRows;
    if (!!props?.params?.key) {
      res = [];
      selectedRows.forEach((item) => {
        res.push(item[props?.params?.key]);
      });
    }
    onChange(res, props?.type ?? "devices");
    setShow(false);
    props.close();
  };

  return (
    <>
      <Modal
        title="选择设备"
        open={show}
        onOk={onSubmit}
        onCancel={onClose}
        destroyOnClose={true}
        bodyStyle={{padding: '0'}}
        className="zk-select-device-modal"
      >
        <ProTableCustom
          rowSelection={{
            defaultSelectedRowKeys: filter ? [] : selectedKeys,
            onChange: (_, selectedRows) => {
              setSelectedRows(selectedRows)
            },
          }}
          tableAlertRender={({
            selectedRowKeys,
          }) => {
            return (
              <span>
                {t("device.alreadySelect")} {selectedRowKeys.length}{" "}
                {t("device.item")}
              </span>
            );
          }}
          columns={props.type === 'door' ? doorTableColumns : deviceTableColumns}
          actionRef={actionRef}
          request={async (params = {}, sort, filter) => {
            let arr = tableData
            if(!!params?.searchParams) {
              let searchValue = params?.searchParams
              // 判断是否是有纯空格（纯空格需要返回空字符串）
              const keywordStatus = searchValue.replace(/\s/g, '').length > 0
              if(!keywordStatus) {
                searchValue = ''
              }
              arr = tableData.filter(item => {
               return  props.type === 'door' ? 
                        item.deviceAlias.indexOf(searchValue) !== -1 :
                        item.deviceAlias.indexOf(searchValue) !== -1 || item.sn.indexOf(searchValue) !== -1
              })
            }
            return {
              data: arr,
              success: true,
              total: arr.length
            };
          }}
          searchPlaceholder={t("device.searchName")}
          rowKey={ props.type === 'door' ? 'doorId' : 'id' }
          toolBar={[]}
          scroll={{ y: '80vh' }}
          reload={false}
          scrollX={false}
        />
      </Modal>
    </>
  );
};

const mapStateToProps = ({ account }) => {
  return {
    orgInfo: account.orgInfo,
  };
};

export default connect(mapStateToProps)(SelectDeviceModal);
