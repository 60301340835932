import React from "react";
import { Result, Button } from "antd";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
// import EditorCustom from "../../components/Editor/Index";
// import TakePicture from "@/components/other/TakePicture";

const NotFound = (props) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  // const onChange = (value) => {
  //   console.log(`--onChange--${value}`)
  // }
  return (
    <>
      <Result
        status="404"
        title="404"
        subTitle={t("error.404")}
        extra={<Button type="primary" onClick={() => navigate('/')}>{t('common.backHome')}</Button>}
      />
      {/* <EditorCustom onChange={onChange}/> */}
      {/* <TakePicture/> */}
    </>
  )
}

export default NotFound